<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
        		The information provided on this website is for general informational purposes only. 
				We are not liable for any damages arising from the use of our website.
			</p>
			<div class="part">
				<h2>
          About GB WhatsApp Banned Account
				</h2>
				<p>
          WhatsApp strictly forbids the use of unauthorized or modified versions of its app and actively 
		  takes measures against accounts using such versions. If your account is blocked due to the use of 
		  GB WhatsApp or any other unofficial app, we are not responsible. We strongly recommend registering 
		  with a secondary mobile number and regularly backing up your chat history and multimedia files.
				</p>
				<h2>
          Third-Party Links				
          </h2>
				<p>
          Our website may contain links to external websites. Once you leave our site and visit a third-party site, we encourage you to review their privacy policies and terms of service. We do not assume responsibility for any losses or damages incurred on third-party websites.
				</p>
				<h2>
          Consent
				</h2>
				<p>
Our website may contain links to external websites. Once you leave our site and visit a third-party site, we encourage you to review their privacy policies and terms of service. We do not assume responsibility for any losses or damages incurred on third-party websites.				</p>
				<h2>
				Changes to this Disclaimer
				</h2>
				<p>
This disclaimer may be updated periodically. Please check this page regularly for any changes.				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'You accept this disclaimer in full by visiting this site. Bookmark this page to check updates.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  